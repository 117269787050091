<template>
  <div class="box grow mh-15 flex column gaps" style="max-width: 700px;">
    <div class="box m-5 flex gaps" v-for="(a, index) in addonsData" :key="index + '-addon'">
      <div class="box left center" style="min-width: 110px;">
          <h5 class="mt-10 mb-5 text-uppercase accent-text">{{a.name}}</h5>
          <h6 class="mt-10 mb-5 secondary-text">{{a.cost}} AED</h6>
      </div>
      <div class="box">
          <el-input-number @change="onChangeAddon" v-model="addonsData[index].count" :step="1" :min="0"></el-input-number>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Addons',

  props: {
    addons: {
      type: Array,
      default: function () {
        return []
      }
    },
    basket: {
      type: Object,
      default: function () {
        return {}
      }
    },
    selectedDay: {
      type: String,
      default: function () {
        return ''
      }
    }
  },

  data () {
    return {
      addonsData: []
    }
  },

  methods: {
    ...mapActions(['selectAddons']),
    onChangeAddon () {
      this.selectAddons(this.addonsData)
    }
  },
  mounted () {
    this.addonsData = this.addons
      .filter(a => a.isShowInBookings)
      .map((x) => Object.assign({ count: 0 }, x))
  },
  activated () {
    if (this.basket.addons.length > 0) {
      this.addonsData = this.addons.map(x => {
        let add = this.basket.addons.find(a => a._id === x._id)
        let count = add !== undefined ? add.count : 0
        return Object.assign({ count }, x)
      })
    } else {
      this.addonsData = this.addons.map((x) => Object.assign({ count: 0 }, x))
    }
  }
}
</script>
