<template>
  <div class="box grow mh-15" style="max-width: 700px;">
    <div class="flex wrap justify-center">
      <div v-for="(v,index) in getVenues" :key="index + '-venue'"
          @click="onClickVenue(v.venueId)"
          class="box grow card-base card-shadow--large m-5 clickable venue-panel"
          :class="[
            {'bg-accent white-text' : selected === v.venueId}
          ]">
        <div class="p-20 flex column gaps">
          <h3 class="box center" :class="[v.isSpecial && selected !== v.venueId ? 'accent-text' : '']">
            {{v.name}}
          </h3>
          <div class="box flex justify-space-between">
            <div class="box m-0 font-size-13 center right"
              :class="[selected === v.venueId ? '' : 'secondary-text']"> {{v.description}} </div>
          </div>
        </div>
      </div>
      <div v-for="(i, index) in extraPanel" :key="index+ '-extra'" class="box grow m-5"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Venues',

  props: {
    venues: {
      type: Array,
      default: function () {
        return []
      }
    },
    blockBooking: {
      type: Number,
      default: function () {
        return 60
      }
    },
    basket: {
      type: Object,
      default: function () {
        return {}
      }
    },
    innerWidth: {
      type: Number,
      default: function () {
        return 0
      }
    }
  },

  data () {
    return {}
  },

  computed: {
    ...mapGetters([
      'getVenues'
    ]),
    selected () {
      return this.basket.venue.id
    },
    extraPanel () {
      if (this.innerWidth >= 750) { // 3
        return 2 - (this.getVenues.length % 3)
      } else if (this.innerWidth >= 520) { // 2
        return 1 - (this.getVenues.length % 2)
      }
      return 0
    }
  },

  methods: {
    ...mapActions(['selectVenue']),
    onClickVenue (id) {
      this.selectVenue({ id, isSame: this.basket.venue.id === id })
    }
  }
}
</script>
