<template>
  <div class="box grow mh-15 flex column gaps" style="max-width: 700px;">
    <div class="box m-5 flex gaps">
      <div class="box left center" style="min-width: 110px;">
          <h5 class="mt-10 mb-5 accent-text">PERSONS</h5>
      </div>
      <div class="box">
          <el-input-number @change="onChangePersons" v-model="persons" :step="1" :min="1"></el-input-number>
      </div>
    </div>
    <div class="flex wrap justify-center">
      <div v-for="s in filtered" :key="s.idx"
          @click="onClickTicket(s.idx)"
          class="box grow card-base card-shadow--large m-5 clickable session-panel flex column"
          :class="[{'bg-accent white-text' : selected === s.idx}]">
        <div class="box grow p-20 flex column gaps">
          <h3 class="box grow center" :class="[s.isSpecial && selected !== s.idx ? 'accent-text' : '']">
            {{s.name}} <span :class="[selected === s.idx ? '' : 'accent-text']" v-if="s.isSpecial"> *</span>
          </h3>
          <div class="box flex justify-space-between">
            <div class="box m-0 fw-700 font-size-18 flex-self-end right"
              :class="[selected === s.idx ? '' : 'secondary-text']"> {{priceToDouble(s.price)}} AED</div>
          </div>
        </div>
      </div>
      <div v-for="(i, index) in extraPanel" :key="index+ '-extra'" class="box grow m-5"></div>
    </div>
    <div class="mv-30 mh-15 center" v-if="(basket.day ==='2024-03-02' || basket.day ==='2024-03-09' || basket.day ==='2024-03-16' || basket.day ==='2024-03-23') && (basket.session.time ==='08:30 - 09:45')">
      Guided roller skate sessions at Rolldxb with internationally renowned professional roller
      skate coach Danielle Lockwood, aka @rollerskillz!

      The cancellation policy is 100% refund if the booking is canceled 24 hours prior the
      scheduled session. No refunds on same day cancelations.
    </div>
    <div class="mv-30 mh-15 center" v-else>
      Your 60 minutes of fun and excitement exclusively at our rolldxb skating rink!
      Please wear long, fresh and clean socks, otherwise we will sell you a pair!
      Unfortunately children aged 5 and below are not allowed in open sessions and group classes. This does not include private classes and and private venue events.
      The cancellations policy for RollDXB is 100% refund if the booking is canceled 24 hours prior to the scheduled session
      No money will be refunded on the same day.
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Tickets',

  props: {
    tickets: {
      type: Array,
      default: function () {
        return []
      }
    },
    basket: {
      type: Object,
      default: function () {
        return {}
      }
    },
    innerWidth: {
      type: Number,
      default: function () {
        return 0
      }
    }
  },

  data () {
    return {
      persons: 1
    }
  },

  computed: {
    selected () {
      return this.basket.ticket.idx
    },
    selectedSession () {
      return this.basket.session.idx
    },
    ...mapGetters([
      'getTypes',
      'isSpecialTypes'
    ]),
    filtered () {
      return this.getTypes
        .map((t, index) => Object.assign({ idx: index }, t))
        .filter(t => t.price !== null && t.price !== 0)
    },
    extraPanel () {
      if (this.innerWidth >= 750) { // 3
        return 3 - (this.filtered.length % 3)
      } else if (this.innerWidth >= 520) { // 2
        return 2 - (this.filtered.length % 2)
      }
      return 0
    }
  },

  methods: {
    ...mapActions(['selectTicket', 'selectPersons', 'selectAddons']),
    onClickTicket (index) {
      this.selectTicket({ idx: index, isSame: this.basket.ticket.idx === index, sessionIdx: this.selectedSession })
    },
    onChangePersons (value) {
      this.selectPersons({ persons: value })
    },
    priceToDouble (value) {
      return Math.floor(value * 100) / 100
    }
  },
  activated () {
    this.persons = this.basket.ticket.persons
  }
}
</script>
