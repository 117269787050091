<template>
  <div class="box grow mh-15 flex column gaps" style="max-width: 700px;">
    <div class="form-box card-base card-shadow--large">
      <div
        class="mb-32 flex gaps"
        v-if="!isAuthenticated">
        <div class="box left">Have you been at the rink already?<br>If Yes, please login.<br>Otherwise continue as a new user.</div>
        <el-button
          class="box center left"
          plain
          type="primary"
          size="medium"
          @click="dialogLoginVisible = true">LOG IN</el-button>
      </div>

      <el-form :label-position="labelPasition" :rules="rules" ref="form" label-width="160px" :model="form">
          <el-form-item label="FIRST NAME" prop="first">
              <el-input :disabled="isAuthenticated" size="medium" v-model="form.first" type="text" name="fname" id="frmNameA" autocomplete="given-name"></el-input>
          </el-form-item>
          <el-form-item label="SURNAME" prop="surname">
              <el-input :disabled="isAuthenticated" size="medium" v-model="form.surname" type="text" name="lname" id="frmNameB" autocomplete="family-name"></el-input>
          </el-form-item>
          <el-form-item label="EMAIL" prop="email">
              <el-input :disabled="isAuthenticated" size="medium" v-model="form.email" type="email" name="email" id="frmEmailA" autocomplete="email"></el-input>
          </el-form-item>
          <el-form-item label="PHONE" prop="phone">
              <el-input :disabled="isAuthenticated" size="medium" v-model="form.phone" type="tel" name="phone" id="frmPhoneNumA" autocomplete="tel"></el-input>
          </el-form-item>
          <el-form-item label="BIRTHDAY" prop="birthday">
              <el-date-picker
                :editable="false"
                type="date"
                size="medium"
                format="yyyy/MM/dd"
                value-format="yyyy-MM-dd"
                v-model="form.birthday">
              </el-date-picker>
          </el-form-item>
          <el-form-item label="PROMOCODE" prop="promocode">
              <!-- <el-input size="medium" v-model="form.promocode"></el-input> -->
              <el-select
                size="medium"
                data-is-search="true"
                allow-create
                default-first-option
                filterable
                v-model="form.promocode"
                placeholder="Choose the promo code after entering it">
                <el-option
                  v-for="item in personalPromosForSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
          </el-form-item>

          <el-form-item prop="confirm" class="mb-5" v-if="!isAuthenticated">
              <el-checkbox v-model="form.confirm" v-show="false"></el-checkbox>
              <div class="flex clickable" @click="dialogTermsVisible = true">
                <i :class="[form.confirm ? 'mdi mdi-checkbox-marked-outline accent-text' : 'mdi mdi-checkbox-blank-outline', 'fs-24 box pr-10']"></i>
                <span :class="[form.confirm ? 'accent-text' : '', 'box left center lh-20']">I agree with RollDXB safety policy and liability waiver agreement *</span>
              </div>
          </el-form-item>
          <el-form-item prop="policy">
              <el-checkbox v-model="form.policy" v-show="false"></el-checkbox>
              <div class="flex clickable" @click="dialogPolicyVisible = true">
                <i :class="[form.policy ? 'mdi mdi-checkbox-marked-outline accent-text' : 'mdi mdi-checkbox-blank-outline', 'fs-24 box pr-10']"></i>
                <span :class="[form.policy ? 'accent-text' : '', 'box left center lh-20']">I agree with cancellation policy*</span>
              </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="submit('form')" class="signin-btn">
              SUBMIT
            </el-button>
          </el-form-item>
      </el-form>
    </div>

    <el-dialog
      title="Cancellation policy of RollDXB"
      :visible.sync="dialogPolicyVisible">
      <p><strong> - 100%</strong> to be refunded if cancelled <u class=""> 24 hours prior to booked session</u>.</p>
      <p><strong> - Zero (Nil)</strong>  if cancelled <u class=""> within 24 hours</u> .</p>
      <p>Please note that you can cancel your session via link sent in confirmation email or by calling us at +97142386206</p>
      <u class="">If your booking is not paid, RollDXB cannot guarantee your skate size.</u>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClickRefusalPolicy">I don't agree</el-button>
        <el-button type="primary" @click="onClickConfirmPolicy">I agree</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="RollDXB terms and conditions"
      :visible.sync="dialogTermsVisible">
      <div class="box">
        <h2>PARK SAFETY RULES</h2>
        <p>1.  Do not participate in any activities above your skill set. </p>
        <p>2.  Aggressive skating should be performed only during the dedicated sessions, and by professional and advanced skaters only.</p>
        <p>3.  Parent/Guardian supervision is required for children under the age of 14.  It is mandatory for children to wear appropriate safety gear (i.e., helmet, knee/elbow pads & wrist support). </p>
        <p>4.  No littering, graffiti, gum, and smoking allowed in Rolldxb.  Any damage to the property will incur a minimum penalty of AED 3,500 or the actual amount of the repair.</p>
        <p>5.  No eating and drinking while on the skating floor. </p>
        <p>6.  Alcohol will only be allowed at licensed events.</p>
        <p>7.  Do not hinder traffic while skating on the skating floor.</p>
        <p>8.  All skaters should move in one direction.</p>
        <p>9.  Be alert, violators of the safety rules will be restricted from the park.</p>

        <h3>COVID-19 GUIDELINE</h3>
        <p>1.  All guests/participants must wear a mask at all times.  You may be allowed to lower/remove your mask only when engaged in skating/performing a dance or stunt routine.</p>
        <p>2.  Regularly wash your hands with soap and water or use hand sanitizer accessible inside the facility.</p>
        <p>3.  Keep physical distance up to 3 meters.</p>
        <p>4.  Any person exhibiting COVID-19 symptoms will not be allowed to participate into the premises.</p>

        <h2>RELEASE AND WAIVER OF LIABILITY AND INDEMNITY AGREEMENT</h2>
        <p><i>(Read Carefully Before Confirming)</i></p>
        <p>I, on my own behalf and on behalf of any person who is a minor by law, and who is in my care or legal guardianship named as a participant below agree that in consideration of being permitted to participate in any skating activity, event or program organized by [ROLL SPORTS & AMUSEMENT TRACKS LLC] a.k.a. (“Rolldxb”) and/or being permitted to enter the premises of Rolldxb (defined as any area where admittance by the general public is controlled by Rolldxb) agree as follows: </p>
        <p>Prior to participating in any skating activity, event or program, I agree that I am responsible for inspecting and instructing minors under my care or legal guardianship, to inspect the facilities and equipment I/ We use, and if any of us believe that anything is unsafe, I shall immediately advise Rolldxb officials of such condition and shall discontinue our participation. For the avoidance of doubt, if at any time I/ We feel anything to be UNSAFE for myself or for the minors in my care or legal guardianship, I/ We will immediately take all precautions to avoid the unsafe area and REFUSE TO PARTICIPATE further.</p>
        <p>I/ We fully understand and acknowledge that: </p>
        <p>(a) there are inherent risks and dangers associated with participation in skating events and activities which could result in bodily injury, partial and/or total disability, paralysis and death; </p>
        <p>(b) the social and economic losses and/or damages could result from these risks and dangers described above, could be severe; </p>
        <p>(c) these risks and dangers may be caused by the action, inaction or negligence of any participant or the action, inaction or negligence of others, including, but not limited to, Rolldxb, its employees, directors, agents, instructors, officials, managers, officers and contractors (the “Releasees”); and </p>
        <p>(d) there may be other risks not known to us or are not reasonable foreseeable at this time. </p>
        <p>I/ WE ACCEPT, ASSUME RESPONSIBILITY FOR SUCH RISKS AS STATED IN PARAGRAPH 2 ABOVE AND THE CLAIMS, LOSSES AND/OR DAMAGES RELATED TO OR ARISING FROM THEM SUCH AS DAMAGE TO PROPERTY, INJURY, DISABILITY, PARALYSIS, DEATH, HOWSOEVER CAUSED AND WHETHER CAUSED IN WHOLE OR IN PART BY THE NEGLIGENCE OF THE RELEASEES, AND ACCORDINGLY I/WE HEARBY RELEASE, WAIVE, DISCHARGE FROM ALL LIABILITY THE RELEASEES IN MY PERSONAL CAPACITY AND/ OR IN ANY OTHER CAPACITY SUCH AS REPRESENTATIVES, ASSIGNS, EXECUTORS, HEIRS AND NEXT TO KIN. </p>
        <p>I/ We Hereby also expressly acknowledges that INJURIES MAY BE COMPOUNDED OR INCREASED BY NEGLIGENT RESCUE OPERATIONS OR PROCEDURES OF THE RELEASEES.</p>
        <p>I/We Hereby acknowledge that PHOTO & VIDEO may be used for social media marketing or other marketing activities purposes.</p>
        <p>I shall indemnify and hold the Releasees harmless from any and all claims, losses, damages, judgments, damages, liabilities, costs and expenses including reasonable attorney’s fees incurred by the Releases or due to third party action against the Releasees arising out of or related to my action or the action of the minor children in my care or legal guardianship including negligence, misuse, error or omission on my part.</p>
        <p>I the undersigned person, on behalf of myself and on behalf of a minor in my care or legal guardianship execute this Release and Waiver of Liability and Indemnity Agreement and agree to indemnify and hold the Releases harmless as a result of the breach of this Agreement. </p>
        <p>I/ We further expressly agree that this Release and Waiver of Liability and Indemnity Agreement is intended to be as broad and inclusive as is permitted by the law of the UAE or the Emirate of Dubai and that if any portion is held invalid, it is agreed that the balance shall, notwithstanding continue in full legal force and effect.</p>
        <p><b>I HAVE READ THIS RELEASE AND WAIVER OF LIABILITY AND INDEMNITY AGREEMENT, AND FULLY UNDERSTAND THE ASSUMPTION OF RISK AND INDEMNITY OBLIGATIONS AND ITS TERMS GENERALLY. I UNDERSTAND THAT I HAVE GIVEN UP SUBSTANTIAL RIGHTS BY SIGNING IT ON BEHALF OF MYSELF AND THE MINORS IN MY CARE OR LEGAL GUARDIANSHIP, AND HAVE SIGNED IT FREELY AND VOLUNTARILY WITHOUT ANY INDUCEMENT, ASSURANCE, OR GUARANTEE BEING MADE TO ME AND INTEND MY SIGNATURE TO BE COMPLETE AND UNCONDITIONAL RELEASE OF ALL LIABILITY TO THE GREATEST EXTENT ALLOWED BY LAW. </b></p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClickRefusalTerms">I don't agree</el-button>
        <el-button type="primary" @click="onClickConfirmTerms">I agree</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Login" :visible.sync="dialogLoginVisible">
      <el-form :label-position="labelPasition" ref="formLogin" label-width="160px" :model="formLogin" :rules="rulesLogin">
        <el-form-item label="EMAIL" prop="email">
          <el-input size="medium" v-model="formLogin.email" type="email" name="email" id="frmEmailB" autocomplete="email"></el-input>
        </el-form-item>
        <el-form-item label="PASSWORD" prop="password">
            <el-input
              v-on:keyup.enter.native="submitLogin('formLogin')"
              size="medium"
              v-model="formLogin.password"
              type="password"
              show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="submitLogin('formLogin')" class="signin-btn">
            LOG IN
          </el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogLoginVisible = false">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Extras',

  props: {
    user: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isAuthenticated: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    basket: {
      type: Object,
      default: function () {
        return {}
      }
    },
    innerWidth: {
      type: Number,
      default: function () {
        return 0
      }
    }
  },

  data () {
    let validateConfirm = (rule, value, callback) => {
      if (value === false) {
        callback(new Error('Please read and accept terms'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        first: [
          { required: true, message: 'Please input first name', trigger: 'blur' }
        ],
        surname: [
          { required: true, message: 'Please input surname', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Please input email address', trigger: 'blur' },
          { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
        ],
        phone: [
          { required: true, message: 'Please input phone number', trigger: 'blur' }
        ],
        birthday: [
          { required: true, message: 'Please pick a date', trigger: 'change' }
        ],
        confirm: [
          { required: true, message: 'Please read and accept terms', trigger: 'blur' },
          { validator: validateConfirm, trigger: 'blur' }
        ],
        policy: [
          { required: true, message: 'Please read and accept terms', trigger: 'blur' },
          { validator: validateConfirm, trigger: 'blur' }
        ]
      },
      form: {
        first: '',
        surname: '',
        email: '',
        phone: '',
        birthday: '',
        promocode: '',
        confirm: false,
        policy: false
      },
      dialogPolicyVisible: false,
      dialogTermsVisible: false,
      dialogLoginVisible: false,
      formLogin: {
        email: '',
        password: ''
      },
      rulesLogin: {
        email: [
          { required: true, message: 'Please input email', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please input password', trigger: 'blur' },
          { min: 6, message: 'Length should be greater 6', trigger: 'blur' }
        ]
      }
    }
  },

  computed: {
    ...mapState({
      personalPromos: state => state.booking.personalPromos
    }),
    ...mapGetters(['personalPromosForSelect']),
    labelPasition () {
      return this.innerWidth > 700 ? 'left' : 'top'
    }
  },

  methods: {
    ...mapActions(['selectExtras', 'login']),
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.selectExtras(this.form)
            .catch(() => {
              this.$message({
                showClose: true,
                message: 'A user with this email already exists. Try to log in or use other email',
                type: 'error'
              })
            })
        }
      })
    },
    submitLogin (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.formLogin.phone = this.formLogin.phone.replaceAll(' ', '')
          this.login(this.formLogin)
            .then(() => {
              this.form = Object.assign({}, {
                birthday: this.user.hasOwnProperty('birthday') ? new Date(this.user.birthday) : '',
                first: this.user.first,
                surname: this.user.surname,
                email: this.user.email,
                phone: this.user.phone.replaceAll(' ', ''),
                promocode: '',
                confirm: true,
                policy: false
              })
              this.dialogLoginVisible = false
            })
            .catch(err => {
              this.$message({
                showClose: true,
                message: 'Error ' + err.message,
                type: 'error'
              })
            })
        }
      })
    },
    onClickConfirmTerms () {
      this.form.confirm = true
      this.dialogTermsVisible = false
    },
    onClickConfirmPolicy () {
      this.form.policy = true
      this.dialogPolicyVisible = false
    },
    onClickRefusalTerms () {
      this.form.confirm = false
      this.dialogTermsVisible = false
    },
    onClickRefusalPolicy () {
      this.form.policy = false
      this.dialogPolicyVisible = false
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      this.form = Object.assign({}, {
        birthday: this.user.hasOwnProperty('birthday') ? new Date(this.user.birthday) : '',
        first: this.user.first,
        surname: this.user.surname,
        email: this.user.email,
        phone: this.user.phone,
        promocode: this.personalPromos.length > 0 ? this.personalPromos[0].code : '',
        confirm: true,
        policy: false
      })
    }
  }
}
</script>
