<template>
<div class="payment box grow mh-15" style="max-width: 700px;">
    <div class="flex column gaps">
        <div class="box grow p-20 card-base card-shadow--large">
            <h3 class="m-0 mb-10">Purchase details:</h3>
            <div class="box grow mt-20 purchase">
                <div class="t-row flex">
                    <div class="box label">Venue:</div>
                    <div class="box value">{{basket.venue.name}}
                      <span v-if="basket.venue.description !== ''" class="secondary-text">, {{basket.venue.description}}</span>
                    </div>
                </div>
                <div class="t-row flex">
                    <div class="box label">Date:</div>
                    <div class="box value">{{step0}}</div>
                </div>
                <div class="t-row flex">
                    <div class="box label">Time:</div>
                    <div class="box value">{{basket.session.time}}</div>
                </div>
                <div class="t-row flex">
                    <div class="box label">Service:</div>
                    <div class="box value">{{basket.session.name}}</div>
                </div>
                <div class="t-row flex">
                    <div class="box label">Tickets:</div>
                    <div class="box value">{{basket.ticket.persons}} {{basket.ticket.name}} (AED {{basket.ticket.persons*basket.ticket.price}})</div>
                </div>
                <!-- <div class="t-row flex">
                    <div class="box label">Persons:</div>
                    <div class="box value">{{basket.ticket.persons}}</div>
                </div>
                <div class="t-row flex">
                    <div class="box label">Price:</div>
                    <div class="box value">{{basket.ticket.price}}AED</div>
                </div> -->
                <div class="t-row flex" v-if="basket.addons.length > 0">
                    <div class="box label">Addons:</div>
                    <div class="box value">
                      <div v-for="(a, index) in basket.addons" :key="index + '-addons'">{{a.count}} {{a.name}} (AED {{a.cost * a.count}})</div>
                    </div>
                </div>
                <div class="t-row flex">
                    <div class="box label">Contact:</div>
                    <div class="box value">{{basket.customer.first + ' ' + basket.customer.surname}}</div>
                </div>
                <div class="t-row flex" v-if="basket.discount.percent > 0">
                    <div class="box label">Discount:</div>
                    <div class="box value">{{basket.discount.percent}}%</div>
                </div>
            </div>
        </div>
        <div class="flex wrap gaps ">
            <div class="box grow bottom pb-20 pt-40 pr-10 total">
                <div class="t-row" v-if="basket.addons.length > 0">
                    <div class="label">Tickets</div>
                    <div class="value">AED {{ticketsAmount}}</div>
                </div>
                <div class="t-row" v-if="basket.addons.length > 0">
                    <div class="label">Addons</div>
                    <div class="value">AED {{addonsAmount}}</div>
                </div>
                <div class="t-row" v-if="basket.discount.percent > 0 || basket.addons.length > 0">
                    <div class="label">Total</div>
                    <div class="value">AED {{total}}</div>
                </div>
                <div class="t-row" v-if="basket.discount.percent > 0">
                    <div class="label">Coupan Discount</div>
                    <div class="value">AED {{discount}}</div>
                </div>
                <div class="t-row tot">
                    <div class="label">Payable Amount</div>
                    <div class="value">AED {{amount}}</div>
                </div>
                <el-button type="primary" size="medium" @click="onClickPayment" class="signin-btn">
                    Confirm & Pay
                </el-button>
            </div>
        </div>
    </div>

    <form :action="hidden.PaymentPage" method="post">
      <input type='Hidden' name='TransactionID' :value="hidden.TransactionID"/>
      <input type="submit" ref='hidden' value="Submit" v-show="false">
    </form>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Payment',

  props: {
    basket: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  data () {
    return {
      hidden: {
        TransactionID: '',
        PaymentPage: ''
      }
    }
  },

  computed: {
    ...mapGetters(['step0']),
    addonsAmount () {
      return this.basket.addons.length > 0 ? this.basket.addons.reduce((a, c) => { return a + (c.count * c.cost) }, 0) : 0
    },
    ticketsAmount () {
      return this.basket.ticket.persons * Math.floor(this.basket.ticket.price * 100) / 100
    },
    total () {
      return this.ticketsAmount + this.addonsAmount
    },
    discount () {
      let count = this.basket.discount.isGroup ? Math.min(this.basket.discount.posibleCount, this.basket.ticket.persons) : 1
      return Math.floor(this.basket.ticket.price * this.basket.discount.percent) * count / 100
    },
    amount () {
      return Math.ceil(this.total * 100 - (this.discount * 100)) / 100
    }
  },

  methods: {
    ...mapActions(['createBook', 'setSplashScreen']),
    onClickPayment () {
      this.setSplashScreen(true)
        .then(() => {
          return this.createBook(this.amount)
        })
        .then(response => {
          this.hidden = response
        })
        .then(() => {
          this.$refs.hidden.click()
        })
        .catch(() => {
          this.$onCommandParams('error')
        })
    }
  }
}
</script>
