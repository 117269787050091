<template>
  <vue-scroll class="register-page ps" ref="scroll">
    <div class="flex column">
      <div class="box left mh-15 center flex justify-flex-start">
        <img class="image-logo"
          @click="goto"
          src="@/assets/images/logo-light.png"
          alt="logo"/>
        <h2 class="box left accent-text">Book now!</h2>
      </div>
      <el-divider class="mv-10"></el-divider>
      <div class="box mh-15" style="max-width: 700px;">
        <el-steps :active="activeStep" align-center ref="steps" class="pv-30">
          <el-step v-for="(s, index) in steps" :key="index + '-' + s.step"
            class="clickable"
            process-status="success"
            finish-status="finish"
           :title="s.step" @click.native="e => onClickStep(e, index)">
            <div slot="description" class="flex column" v-if="innerWidth >= 700">
              <div v-for="(d, idx) in descriptions[index]" :key="idx + '-desc'">
                {{d}}
              </div>
            </div>
           </el-step>
        </el-steps>
      </div>
      <div class="m-15" v-if="isNext">
        <el-button @click="selectStep({ idx: (activeStep + 1) })" type="primary">Next</el-button>
      </div>
      <transition name="fade" mode="out-in">
        <keep-alive>
          <app-calendar
            v-if="activeStep === 0"
            :basket="basket" @setDay="setDay($event)"></app-calendar>
          <app-venues
            v-if="activeStep === 1"
            :innerWidth="innerWidth"
            :venues="venues"
            :blockBooking="blockBooking"
            :basket="basket"></app-venues>
          <app-sessions
            v-if="activeStep === 2"
            :innerWidth="innerWidth"
            :blockBooking="blockBooking"
            :basket="basket"></app-sessions>
          <app-tickets
            v-if="activeStep === 3"
            :innerWidth="innerWidth"
            :tickets="tickets"
            :basket="basket"></app-tickets>
          <app-addons
            v-if="activeStep === 4"
            :addons="addons"
            :basket="basket"
            :selectedDay="selectedDay"></app-addons>
          <app-details
            v-if="activeStep === 5"
            :user="user"
            :basket="basket"
            :isAuthenticated="isAuthenticated"
            :innerWidth="innerWidth"></app-details>
          <app-payment
            :basket="basket"
            v-if="activeStep === 6"></app-payment>
        </keep-alive>
      </transition>
    </div>

  </vue-scroll>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import AppCalendar from './Calendar'
import AppVenues from './Venues'
import AppSessions from './Sessions'
import AppTickets from './Tickets'
import AppAddons from './Addons'
import AppDetails from './Details'
import AppPayment from './Payment'

export default {
  name: 'Booking',

  props: {
    selectedDay: {
      type: String,
      default: function () {
        return ''
      }
    }
  },

  components: {
    AppCalendar, AppVenues, AppSessions, AppTickets, AppAddons, AppDetails, AppPayment
  },

  data () {
    return {
      innerWidth: 0,
      innerHeight: 0
    }
  },

  computed: {
    ...mapState({
      blockBooking: state => state.booking.settings.blockBooking,
      tickets: state => state.booking.tickets,
      addons: state => state.booking.addons,
      basket: state => state.booking.basket,
      steps: state => state.booking.steps,
      user: state => state.auth.user,
      activeStep: state => state.booking.activeStep,
      venues: state => state.booking.venues
    }),
    ...mapGetters([
      'isAuthenticated',
      'descriptions',
      'calendarDaysArray',
      'getSessions',
      'getVenues'
    ]),
    id () {
      return this.isAuthenticated ? this.user._id : undefined
    },
    isNext () {
      return this.activeStep === 4 || this.nextCalc(this.activeStep)
    }
  },

  methods: {
    ...mapActions(['selectStep', 'selectDay', 'selectSession', 'selectSession', 'selectVenue']),
    nextCalc (step) {
      return (step < 1 && this.basket.day !== '') ||
      (step < 2 && this.basket.venue.id !== '') ||
      (step < 3 && this.basket.session.idx !== -1) ||
      (step < 4 && this.basket.ticket.idx !== -1) ||
      (step < 5 && this.basket.addons.length > 0) ||
      (step < 6 && this.basket.customer.id !== '')
    },
    onClickStep (e, index) {
      if (index === 0 || this.nextCalc(index - 1)) {
        this.selectStep({ idx: index })
      }
    },
    resizeOpenNav () {
      this.innerWidth = window.innerWidth
      this.innerHeight = window.innerHeight
    },
    goto () {
      if (this.isAuthenticated) {
        this.$onCommandParams('home')
      } else {
        this.$onCommandParams('login')
      }
    },
    async parseString (string) {
      let str = string.split('_')
      if (str.length > 0) {
        let date = str[0]
        let today = new Date().getTime()
        if (this.calendarDaysArray.includes(date) && (new Date(date).getTime() > today - 86400000) && (new Date(date).getTime() < today + (86400000 * 30))) {
          await this.selectDay({ day: date, isSame: false })

          if (str.length > 1) {
            let venueIndex = Number(str[1])
            if (typeof venueIndex === 'number') {
              let venue = this.getVenues[venueIndex]
              if (venue.hasOwnProperty('venueId')) {
                await this.selectVenue({ id: venue.venueId, isSame: false })

                if (str.length > 2) {
                  let sessionIndex = Number(str[2])
                  if (typeof sessionIndex === 'number' && this.getSessions[sessionIndex].left > 0) {
                    await this.selectSession({ idx: sessionIndex, isSame: false })
                  }
                }
              }
            }
          }
        }
      }
    },
    setDay (value) {
      // this.selectedDay = value
      this.$emit('update:selectedDay', value)
    }

  },
  activated () {
    this.resizeOpenNav()
    window.addEventListener('resize', this.resizeOpenNav)
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
    if (this.$route.params.string !== undefined) {
      this.parseString(this.$route.params.string)
    }
  },
  deactivated () {
    window.removeEventListener('resize', this.resizeOpenNav)
  },
  watch: {
    activeStep: function (val, oldVal) {
      this.$nextTick(() => {
        this.$refs.scroll.$el.scrollTop = 97
      })
    }
  }
}
</script>
